<template>
    <div class="uplate">
        <section class="app-user-list">
            <div class="card">
                <div class="card-datatable table-responsive pt-0">
                    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="d-flex justify-content-between align-items-center header-actions mx-1 row mt-75">
                            <div class="col-lg-12 col-xl-6">

                            </div>
                           <!-- <div class="col-lg-12 col-xl-6 pl-xl-75 pl-0">
                                <div class="dt-action-buttons text-xl-right text-lg-left text-md-right text-left d-flex align-items-center justify-content-lg-end align-items-center flex-sm-nowrap flex-wrap mr-1">
                                    <div class="mr-1">
                                        <div id="DataTables_Table_0_filter" class="dataTables_filter"><label>Pretraga:<input type="search" class="form-control" placeholder="" aria-controls="DataTables_Table_0"></label>
                                        </div>
                                    </div>
                                    <div class="dt-buttons btn-group flex-wrap">
                                        <button class="btn add-new btn-primary mt-50" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#modals-slide-in">
                                            <span>Dodaj Novog</span>
                                        </button>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <table class="user-list-table table dataTable no-footer dtr-column" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                            <thead class="thead-light">
                            <tr role="row">
                                <th class="control sorting_disabled" rowspan="1" colspan="1" aria-label="">ID</th>
                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"  aria-label="User: activate to sort column ascending">Ime i Prezime</th>
                                <th class="sorting_desc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-sort="descending" aria-label="Email: activate to sort column ascending">Email</th>
                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Role: activate to sort column ascending">kolicina</th>
                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Plan: activate to sort column ascending">datum</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="odd" v-for="uplata in uplate " v-bind:key="uplata.id">
                                <td valign="top" colspan="1">{{uplata.id}}</td>
                                <td valign="top" colspan="1">{{uplata.ime+' '+uplata.prezime}}</td>
                                <td valign="top" colspan="1">{{uplata.email}}</td>
                                <td valign="top" colspan="1">{{uplata.value}} KM</td>
                                <td valign="top" colspan="1">{{uplata.created_at}}</td>

                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- list section end -->
        </section>
    </div>
</template>
<script>
    import api from '../api'
  export default {
    name: 'uplate',
    components: {
    },  data() {
          return {
              uplate:null,
              serverRoot:process.env.VUE_APP_SERVER_ROOT,
          }
      },
      mounted(){
          this.getUplate().then(data => {
              this.uplate=data.data.uplate;
              this.loading=false;
          });
      },
      methods:{
          async getUplate() {
              return api.getUplate();
          },
      }
  }
</script>
<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
</style>
